import { useMemo } from 'react';

import {
  Box,
  Button,
  Link as MuiLink,
  Paper,
  Stack,
  Typography,
  colors,
  lighten,
  styled,
} from '@mui/material';
import { lowerCase } from 'lodash';
import { BsCodeSlash, BsEye } from 'react-icons/bs';
import ReactPlayer from 'react-player/youtube';
import { Link } from 'react-router-dom';

import { CardTagChip } from '@components/CardTagChip';
import HorizontalScroller from '@components/HorizontalScroller';
import { Image } from '@components/Image';
import { StackRow } from '@components/index';
import { AppRoutes } from '@constants/appRoutes';
import useLikeDislikeManager, { LikeDislikeManagerProps } from '@hooks/useLikeDislike';
import useResponsive from '@hooks/useResponsive';
import { useRouter } from '@hooks/useRouter';
import { isValidTagArray } from '@utility/array';
import { likeDislikeTypeFromValue } from '@utility/likesDislikes';
import { numberToWords } from '@utility/numberToWords';
import { TrendingPostsResponse } from 'api/post/types';

import Author from './Author';

type Props = TrendingPostsResponse[number] & {
  onChange?: (postId: number) => LikeDislikeManagerProps['onChange'];
};

function Card({ onChange, ...data }: Props) {
  const { isDownLg } = useResponsive();

  const likeDislikeType = useMemo(() => {
    return likeDislikeTypeFromValue(data.activeUserVote);
  }, [data.activeUserVote]);

  useLikeDislikeManager(
    +data.likeCount ?? 0,
    +data.dislikeCount ?? 0,
    likeDislikeType,
    data.id,
    onChange?.(data.id)
  );

  const { searchParams } = useRouter();

  searchParams.set('drawer_id', `${data.id}`);

  const tags = isValidTagArray(data.tags);
  const langs = isValidTagArray(data.langs);

  const infoButtonIconSize = 16;

  const actionBar = (
    <CardActionStack
      spacing={{ xs: 1, lg: 3 }}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        pl: 0.54,
        my: { xs: 0.9, lg: 0.9 },
        py: 0.4,
        width: '100%',
        border: '1px solid #eee',
        borderWidth: '1px 0 1px 0',
      }}
    >
      <StackRow spacing={{ xs: 1, lg: 2 }} alignItems='center' justifyContent='flex-start'>
        <Author author={data.author} />
      </StackRow>
    </CardActionStack>
  );

  return (
    <Paper
      variant='elevation'
      sx={{
        bgcolor: 'white',
        boxShadow: 'none',
        p: 2,
        pb: { xs: 0, lg: 1 },
        '&:hover': { bgcolor: lighten(colors.blueGrey[50], 0.5) },
      }}
    >
      {data.youtubeUrl && (
        <Box pb={4}>
          <ReactPlayer
            url={data.youtubeUrl}
            light
            volume={40}
            width={isDownLg ? window.innerWidth : undefined}
          />
        </Box>
      )}
      <Stack
        direction='row'
        alignItems={{ xs: 'flex-start', lg: 'flex-start' }}
        justifyContent='space-between'
      >
        <Box width={{ xs: '3.5rem', lg: '5.5rem' }}>
          <Image
            avatar
            sx={{
              width: { xs: '3rem', lg: '5.5rem' },
              height: { xs: '3rem', lg: '5.5rem' },
              borderRadius: { xs: '1rem', lg: '1rem' },
            }}
            src={data.thumbnailUrl as string}
            alt={data.name}
          />
        </Box>
        <Box
          sx={{
            p: { xs: '0 0 0.5rem 0.7rem', lg: '0 0 0 0' },
            width: { xs: `calc(100% - 3rem)`, lg: `calc(100% - 7rem)` },
            justifySelf: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: '0.95rem', lg: '1rem' },
              mr: 1,
              wordBreak: 'keep-all',
            }}
          >
            <MuiLink underline='hover' component={Link} to={{ search: searchParams.toString() }}>
              {data.name}
            </MuiLink>{' '}
            -{' '}
            <Typography
              component='span'
              sx={{
                color: lighten('#000', 0.1),
                fontWeight: '500',
                fontSize: { xs: '0.85rem', lg: '0.97rem' },
                wordBreak: 'keep-all',
              }}
            >
              {data.tagline}
            </Typography>
          </Typography>

          {tags && (
            <HorizontalScroller disableArrow>
              <Stack
                spacing={{ xs: 0.4, lg: 1 }}
                direction='row'
                alignItems='center'
                justifyContent='flex-start'
                sx={{ maxWidth: '100%', ml: -1 }}
              >
                {tags.map((item) => (
                  <Link
                    key={item.id}
                    to={AppRoutes.postsByTag({
                      id: `${item.id}`,
                      tagName: lowerCase(item.name).replaceAll(' ', '-'),
                    })}
                  >
                    <CardTagChip name={item.name} />
                  </Link>
                ))}
              </Stack>
            </HorizontalScroller>
          )}
          {langs && (
            <HorizontalScroller disableArrow>
              <Stack
                spacing={{ xs: 0.4, lg: 1 }}
                direction='row'
                alignItems='center'
                justifyContent='flex-start'
                sx={{ maxWidth: '100%', ml: -0.4 }}
              >
                {langs.map((item) => (
                  <CardTagChip
                    key={item.id}
                    name={item.name}
                    icon={<BsCodeSlash style={{ marginRight: '0.6px' }} />}
                  />
                ))}
              </Stack>
            </HorizontalScroller>
          )}
          {!isDownLg && actionBar}
        </Box>
      </Stack>
      {isDownLg && actionBar}
    </Paper>
  );
}

export default Card;

const CardActionStack = styled(Stack)(({ theme }) => ({
  '.author-btn': {
    minWidth: '100%',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiButton-root': {
      minWidth: '3rem',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '0.6rem',
      borderRight: '1px solid #fff',
      '& .MuiButton-startIcon': {
        margin: '0 0',
      },
    },
  },
}));
